import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Button from 'src/components/atoms/button';
import CarouselArrowButtonStyle from './style';
import { CarouselArrowDirection } from '@common/enum/learningPath';

type CarouselArrowButtonProps = {
  direction: CarouselArrowDirection.LEFT | CarouselArrowDirection.RIGHT;
  onClick?: () => void;
  isFixTop?: boolean;
  isArrowSingleRow?: boolean;
  className?: string;
};

const CarouselArrowButton = ({
  direction,
  onClick,
  isFixTop,
  className,
  isArrowSingleRow,
}: CarouselArrowButtonProps) => (
  <CarouselArrowButtonStyle
    isFixTop={isFixTop}
    direction={direction}
    className={className}
    isArrowSingleRow={isArrowSingleRow}
  >
    <Button className="arrow-button" onClick={onClick} aria-label="circle-button">
      {direction === CarouselArrowDirection.LEFT ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
    </Button>
  </CarouselArrowButtonStyle>
);

export default CarouselArrowButton;
